import React from 'react'
import styled from "styled-components"
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'

import Seo from "../components/Seo"
import PageTitle from '../components/PageTitle'
import NewsletterCTA from '../components/NewsletterCTA'

const PageContainer = styled.section`
  margin-bottom: 120px;

  .container {
    max-width: 800px;
    margin: 0 auto;
    color: var(--gray);
    margin-bottom: 50px;
    
    p {
      line-height: 1.4;
      margin-bottom: 30px;
    }

    a {
      color: var(--pink);
    }

    ul,
    ol {
      margin-top: 0;
      margin-bottom: 30px;

      li::marker {
        color: var(--pink);
      }

      p {
        margin-bottom: 0;
      }
    }

    .gatsby-image-wrapper {
      margin-bottom: 30px;
    }
  }
`

function Page({ pageContext }) {
  const { page } = pageContext

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <i>{text}</i>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => <a href={node.data.uri}>{children}</a>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData } = node.data.target
        if (!gatsbyImageData) {
          return null
        }
        return <GatsbyImage image={gatsbyImageData} alt={node.data.target.title} />
      },
    },
  }

  return (
    <main>
      <PageTitle title={page.pageTitle} paragraph="" />
      <PageContainer>
        <div className="container">
          {page.pageContent && renderRichText(page.pageContent, options)}
        </div>
      </PageContainer>
      <NewsletterCTA />
    </main>
  )
}

export default Page

export const Head = (pageContext) => {
  return (
    <>
      <Seo 
        key={pageContext.pageContext.page.id} 
        title={pageContext.pageContext.page.seoTitle}
        description={pageContext.pageContext.page.seoTitle}
        image={pageContext.pageContext.page.seoImage.url}
      />
    </>
  )
}